import { render, staticRenderFns } from "./ProductSeriesHeader.vue?vue&type=template&id=32052e41"
import script from "./ProductSeriesHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProductSeriesHeader.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSectionTitle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiSectionTitle.vue').default,UiLink: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiLink.vue').default,UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiIcon.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiButton.vue').default})
