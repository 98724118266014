import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5ef30c40 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _0ee93174 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _ccc05e12 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _e7eaed46 = () => interopDefault(import('../pages/search/documents.vue' /* webpackChunkName: "pages/search/documents" */))
const _cc8c4762 = () => interopDefault(import('../pages/search/products.vue' /* webpackChunkName: "pages/search/products" */))
const _4d857eb6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5d3c6af8 = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _19b2181a = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _2a7d7764 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _4bcedd6e = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _5ef30c40,
    name: "catalog___en"
  }, {
    path: "/search",
    component: _0ee93174,
    children: [{
      path: "",
      component: _ccc05e12,
      name: "search___en"
    }, {
      path: "documents",
      component: _e7eaed46,
      name: "search-documents___en"
    }, {
      path: "products",
      component: _cc8c4762,
      name: "search-products___en"
    }]
  }, {
    path: "/",
    component: _4d857eb6,
    name: "index___en"
  }, {
    path: "/catalog/:slug",
    component: _5d3c6af8,
    name: "catalog-slug___en"
  }, {
    path: "/news/:slug?",
    component: _19b2181a,
    name: "news-slug___en"
  }, {
    path: "/product/:slug?",
    component: _2a7d7764,
    name: "product-slug___en"
  }, {
    path: "/:slug",
    component: _4bcedd6e,
    name: "slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
